import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

Sentry.init({
    dsn: 'https://31e2bcfe29f25c70b8f0d75f1e91dcc8@o4507084963774464.ingest.us.sentry.io/4507554603597824',
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
        Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
        }),
        Sentry.replayIntegration(),
    ],
})

i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
        fallbackLng: 'he',
        lng: 'he',
        backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
        interpolation: {
            escapeValue: false,
        },
    })

startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <RemixBrowser />
        </StrictMode>
    )
})
